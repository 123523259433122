.token_details {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: 20px;
}

.token_details .chip_info {
    text-align: center;
    margin-right: 40px;
}

.token_details .chip_info:last-child {
    margin-right: unset;
}

.token_details .chip {
    border-radius: 50px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin: 10px 0;
    width: fit-content;
    padding: 10px 40px;
    height: 50px;
    margin: 10px auto;
}

.token_details .chip > img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
}

.token_details .chip p {
    font-family: 'Blinker', sans-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    color: #FFF;
    width: max-content;
}

.token_details .chip_info > p {
    color: #000;
    text-align: center;
    font-family: 'Blinker', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; 
    width: max-content;
    margin: auto;
}

.token_details .outline_button {
    border-radius: 100px;
    border: 1px solid #000;
    height: 26px;
flex-shrink: 0;

    color: #000;
    text-align: center;
    font-family:'Blinker', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}

.token_details .buttons_div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.token_details .buttons_div > span {
    height: 20px;
    border: .5px solid #FFFFFF;
    margin: 0 10px;
}

@media (max-width: 2000px) {
    .token_details {
        margin: 40px 0 0;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .token_details .chip_info {
        margin-bottom: 20px;
    }
}

@media (max-width: 1300px) {
    .token_details {
        justify-content: space-around;
    }
}

@media (max-width: 426px) {
    .token_details {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .token_details .chip_info {
        margin: 0 0 20px;
        width: 100%;
    }
}
